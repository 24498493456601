<template>
  <div v-if="hasExcelDataImportCapability">
    <t-modal :id="modalId"
             :ok-disabled="okBtnDisabled"
             ref="importExcelModal"
             title="Import Excel Data"
             ok-title="Apply Updates"
             ok-variant="warn"
             size="md"
             @ok="okClick"
             @show="modalShow"
             @hide="modalHide">
      <t-row>
        <t-col cols="6">
          <p class="mb-0">If you've downloaded a data sheet from Fluency and made changes you can:</p>
          <ul>
            <li>Upload that Excel (xls/xlsx) file here</li>
            <li>Preview the diffs</li>
            <li>Apply changes</li>
          </ul>
        </t-col>
        <t-col cols="6" offset="0">
          <file-upload :allowed-types="['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']"
                       placeholder="Upload your edited Fluency Excel export file here"
                       endpoint="export/up"
                       layout="vertical"
                       @success="successfulFileUpload"></file-upload>
        </t-col>
      </t-row>
      <template v-if="badUpload">
        <h2 class="mt-3 mb-4">Something Went Wrong:</h2>
        <p class="alert alert-danger"><b>Error:</b> {{badUpload}}</p>
        <p>If you are unable to resolve this issue by editing your Excel file, please contact your Fluency rep.</p>
      </template>
      <template v-if="hasResponse">
        <h2 class="mt-3 mb-4">Detected Updates:</h2>
        <p v-if="emptyResponse" class="alert alert-warning">
          No differences found between Fluency data and your Excel file.
        </p>
        <p-tabs>
          <p-tab-list class="sticky-top">
            <template v-for="(update, updateKey) in updates" :key="updateKey">
              <p-tab :value="updateKey" v-if="update.length || errors[updateKey].length">
                {{friendlyKeys(updateKey)}}
                <b-badge v-if="update.length && updatesCategories[updateKey]"
                         pill
                         :variant="(updatesCategories[updateKey].count === 0) ? 'light' : 'warning'">
                  <span v-if="updatesCategories[updateKey].count === 0" class="text-muted">&ndash;</span>
                  <template v-else>{{updatesCategories[updateKey].count}}</template>
                </b-badge>
              </p-tab>
            </template>
          </p-tab-list>
          <p-tab-panels>
            <template v-for="(update, updateKey) in updates" :key="updateKey">
              <p-tab-panel :value="updateKey" v-if="update.length || errors[updateKey].length">
                <ul class="list-unstyled px-4 mt-3 mx-3 border">
                  <li class="my-3 py-3 border-bottom">
                    <div class="d-flex">
                      <b-checkbox v-if="!showErrors[updateKey] && update.length"
                                  :checked="updatesCategories[updateKey].all"
                                  :indeterminate="updatesCategories[updateKey].some"
                                  @update:indeterminate="updatesCategories[updateKey].some = $event"
                                  @change="updatesCategories[updateKey].all = $event; categoryClicked($event, updateKey)">
                        <i>Select All</i>
                      </b-checkbox>
                      <b v-if="showErrors[updateKey]">
                        Errors <b-badge pill variant="danger">{{errors[updateKey].length}}</b-badge>
                      </b>
                      <div v-if="errors[updateKey].length > 0 && update.length > 0"
                           class="flex-grow-1 text-right">
                        <b-btn variant="link" class="py-0" @click="toggleView(updateKey)">
                          <span v-if="!showErrors[updateKey]">Show Errors <b-badge pill variant="danger">{{errors[updateKey].length}}</b-badge></span>
                          <span v-if="showErrors[updateKey]">Show Updates</span>
                        </b-btn>
                      </div>
                      <div v-if="errors[updateKey].length && update.length === 0"
                           class="flex-grow-1 text-right">
                        <i>No {{friendlyKeys(updateKey)}} updates</i>
                      </div>
                    </div>
                  </li>
                  <template v-if="showErrors[updateKey] === false">
                    <li v-for="(item, itemKey) in update"
                        :key="`item-${itemKey}`"
                        class="py-2"
                        :class="{
                                'mb-3': update.length === itemKey + 1
                              }">
                      <b-checkbox :checked="item.selected"
                                  class="word-break-break-word"
                                  @change="item.selected = $event; checkForAll($event, updateKey)">
                        <b>{{item.path}}:</b>
                        <div class="ml-4">
                        <span v-for="(change, changeKey) in item.changes" :key="changeKey">
                          {{change}}<br />
                        </span>
                        </div>
                      </b-checkbox>
                    </li>
                    <li v-if="update.length === 0" class="py-2 mb-3"><i>No {{friendlyKeys(updateKey)}} Updates</i></li>
                  </template>
                  <template v-if="showErrors[updateKey] === true && errors[updateKey].length > 0">
                    <li v-for="(error, errorKey) in errors[updateKey]"
                        :key="`error-${errorKey}`"
                        class="py-2"
                        :class="{
                                'mb-3': errors[updateKey].length === errorKey + 1
                              }">{{error}}</li>
                  </template>
                </ul>
              </p-tab-panel>
            </template>
          </p-tab-panels>
        </p-tabs>
      </template>
    </t-modal>
    <div class="text-right">
      <action-icon icon="upload"
                   buttonText="Upload"
                   variant="link-info"
                   v-p-tooltip="'Import an Advanced Download file and review changes'"
                   @click.prevent="$refs.importExcelModal.show()" />
    </div>
  </div>
</template>

<script>
import _capitalize from 'lodash.capitalize'
import _cloneDeep from 'lodash.clonedeep'
import ActionIcon from '@/components/pages/manage/actionIcon'
import FileUpload from '../forms/fileUpload'

export default {
  name: 'importExcel',
  components: { ActionIcon, FileUpload },
  data () {
    return {
      modalId: 'import-excel-file-uploader',
      response: null,
      badUpload: false,
      updates: {},
      errors: {},
      showErrors: {},
      updatesCategories: {}
    }
  },
  computed: {
    hasExcelDataImportCapability () {
      return this.$store.getters.user.capabilities?.ExcelDataImport ?? false
    },
    okBtnDisabled () {
      return !this.hasResponse || !this.hasSelections
    },
    hasResponse () {
      return this.response !== null
    },
    emptyResponse () {
      let any = true

      if (this.response) {
        this.response.forEach(r => {
          if (r.items && r.items.length > 0) {
            any = false
          }
        })
      }

      return any
    },
    hasSelections () {
      // good ol' fashioned for-loop so we can break out with a return
      for (const key in this.updatesCategories) {
        const val = this.updatesCategories[key]

        if (val.all || val.some) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    init () {
      this.response = null
      this.updates = {}
      this.errors = {}
      this.showErrors = {}
      this.updatesCategories = {}
      this.badUpload = false
    },
    modalShow () {
      this.init()
    },
    modalHide () {
      this.init()
    },
    okClick (e) {
      this.sendUpdates()
    },
    async sendUpdates () {
      const payload = {}

      Object.entries(this.updates).forEach(update => {
        const updateKey = update[0]
        const updateVal = update[1]

        if (!payload.hasOwnProperty(updateKey)) {
          payload[updateKey] = []
        }

        updateVal.forEach(change => {
          if (change.selected) {
            payload[updateKey].push(change.updated)
          }
        })
      })

      const response = await this.$res.set.excelImport(payload)

      if (response) {
        this.$toast('Your Excel Import has been received and changes have been applied.', 'success')

        // TODO: in the future we might need to re-init Manage if account level things change.
        // this.$store.dispatch('initManage', {
        //   route: this.$route,
        //   previousRoute: this.previousRoute,
        //   params: this.$route.params,
        //   query: this.$route.query
        // })
      }
    },
    categoryClicked (e, key) {
      const arrCopy = _cloneDeep(this.updates[key])

      arrCopy.forEach((update, i) => {
        update.selected = e
      })

      this.updates[key] = arrCopy

      this.checkForAll(e, key)
    },
    checkForAll (e, key) {
      let all = true
      let some = false
      let count = 0

      this.updates[key].forEach(item => {
        if (item.selected) {
          some = true
          count++
        } else {
          all = false
        }
      })

      if (all) {
        some = false
      }
      this.updatesCategories[key] = { all, some, count }
    },
    successfulFileUpload (response) {
      this.init()

      if (typeof response === 'string') {
        this.badUpload = response
        return
      }
      this.response = response

      if (Array.isArray(this.response)) {
        this.response.forEach(category => {
          const key = category.type || null
          const items = category.items || []
          const errors = category.errors || []

          if (!this.updates.hasOwnProperty(key)) {
            this.updates[key] = []
            this.errors[key] = []
            this.showErrors[key] = false
            this.updatesCategories[key] = { all: false, some: false, count: 0 }
          }

          items.forEach(account => {
            if (account.differences) {
              account.differences.map(difference => {
                const item = { ...difference, selected: false }
                this.updates[key].push(item)
              })
            }
          })

          // case of no updates, but errors present
          if (items.length === 0 && errors.length > 0) {
            this.showErrors[key] = true
          }

          errors.forEach(error => {
            this.errors[key].push(error)
          })
        })
      }
    },
    toggleView (key) {
      this.showErrors[key] = !this.showErrors[key]
    },
    friendlyKeys (key) {
      const map = {
        adgroups: 'Ad Groups',
        callouts: 'Call-Outs'
      }

      return map[key] || _capitalize(key)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
